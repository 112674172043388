import { serviceClient } from "./client";

const adminConsoleAPI = {};

adminConsoleAPI.getSchema = async (adminConsoleConfig) => {
  // console.log("adminConsoleConfig", adminConsoleConfig);
  const queryParams = adminConsoleConfig.path.schema.query_params;
  return await serviceClient.get(
    `${adminConsoleConfig.url.base}${adminConsoleConfig.path.schema.name}`,
    {
      params: queryParams,
    }
  );
};

export default adminConsoleAPI;
