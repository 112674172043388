const initialState = {
  query: "",
  filters: {},
  config: {},
  response: null,
  suggestionsDebug: [],
  spellOpt: "DEFAULT",
  services: {
    rules: {
      name: "Rules",
      enabled: false,
      field: "enableRules",
      responseFilter: "debug.api.meta.serviceResponse.searchRulesResponse",
    },
    spellCheck: {
      name: "Spellcheck",
      enabled: false,
      field: "enableAutoCorrection",
      responseFilter: "debug.api.meta.serviceResponse.spellCorrectedResponse",
    },
    dynamicFacets: {
      name: "Dynamic Facets",
      enabled: false,
      field: "enableDynamicFacets",
      responseFilter: "debug.api.meta.serviceResponse.dynamicFacetResponse",
    },
    // boosting: { name: "Ranking", enabled: false, field: "productsBoostEnabled" },
    qps: {
      name: "QPS",
      enabled: false,
      field: "enableQueryParser",
      responseFilter: "debug.api.meta.serviceResponse.queryParsedResponse",
    },
  },
};

export default initialState;
