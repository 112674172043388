export const bff = "https://searchbffqa2.tataunistore.com/products/mpl/search/";
const prod = "https://prodsearch.tatacliq.com/products/mpl/search/";
const URL = prod;
const usp = new URLSearchParams(
  "?searchText=%3Arelevance%3Acategory%3AMSH1311115%3AinStockFlag%3Atrue&isKeywordRedirect=true&isKeywordRedirectEnabled=true&channel=WEB&isMDE=true&isTextSearch=false&isFilter=false&qc=false&test=mm.new-inviz&page=0&isSuggested=false&isPwa=true&pageSize=40&typeID=all"
);

export const getSearchText = (q) => {
  return `${q}:relevance:inStockFlag:true`;
};

export const getURL = (q) => {
  usp.set("searchText", getSearchText(q));
  console.log(getSearchText(q), usp.get("searchText"), usp);
  return `${URL}?${usp.toString()}`;
};

export const Field = (mapping, field, data) => {
  // alert(data)
  // console.log(data)
  // console.log("DD",mapping,field,data)
  try {
    if (typeof mapping[field] == "function") {
      // console.log(field, "is a function",mapping[field])
      return mapping[field](data);
    } 
    else if (typeof data !== "object") {
      return mapping[field];
    } 
    else {
      // have fallbacks
      return data[mapping[field]] || mapping[field] || field;
    }
  } catch (err) {
    return field;
  }
};
