import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import useStateValue from "../hooks/useStateValue";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { useState } from "react";

function Filters() {
  const [state, dispatch] = useStateValue();
  const filters = state.filters;
  const [mappings, setMappings] = useState({});

  const onChangeFilters = (key, value) => {
    const newFilters = { ...state.filters };
    if (newFilters[key]) {
      if (newFilters[key].includes(value)) {
        const index = newFilters[key].findIndex((e) => e == value);
        newFilters[key].splice(index, 1);
      } else {
        newFilters[key].push(value);
      }
    } else {
      newFilters[key] = [value];
    }
    dispatch({ type: "SET_STATE", state: { filters: newFilters } });
  };

  useEffect(() => {
    const newMappings = state.response?.meta?.facets?.reduce((acc, e) => {
      acc[e.label] = e.displayName;
      return acc;
    }, {});
    if (newMappings) {
      setMappings(newMappings);
    }
  }, [state.response?.meta?.facets]);

  return (
    <Box display="flex" flexWrap="wrap">
      {Object.keys(filters).map((e) => {
        const values = filters[e];
        return (
          <Box display="flex" flexWrap="wrap">
            {values.map((value) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  className="filterText"
                  onClick={() => onChangeFilters(e, value)}
                >
                  <Typography variant="caption">
                    {mappings[e]||e} : {value}
                  </Typography>
                  <CloseIcon style={{ fontSize: 14 }} />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

export default Filters;
