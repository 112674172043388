import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Input,
  Select,
  MenuItem,
  IconButton,
  Icon,
  Table,
  TableHead,
  TableCell,
  Slide,
  TableBody,
  TableRow,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { dark_vscode_tribute } from "react-json-editor-ajrm/themes";
import ReactJson from "react-json-view";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, BrowserRouter as Router, useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import AutoSuggest from "./AutoComplete";
import useStateValue from "../hooks/useStateValue";
import { setSession } from "../auth";
import searchAPI from "../api/search";
import autocompleteIcon from "../assets/autocomplete.png"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const convertConfig = (d) => {
  const f = {};
  for (const key in d) {
    if (typeof d[key] == "string") {
      const matchesWithLambada = d[key].match(/(\w+)\=\>/)
      if (matchesWithLambada) {
        const func = d[key].slice(3);
        const ff = Function(matchesWithLambada[1], `return ${func}`);
        f[key] = ff;
      } else f[key] = d[key];
    }
    if (typeof d[key] == "object") {
      f[key] = convertConfig(d[key]);
    }
  }
  return f;
};

const Navbar = () => {
  const [configOpen, setConfigOpen] = useState(false);
  const [addNewDialog, setAddNewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState("");
  const [newConfig, setNewConfig] = useState("");
  const [searchRequest, setSearchRequest] = useState({});
  // const [state.responsePopup, setResponsePopup] = useState(false);
  const [debugQuery, setDebugQuery] = useState("");
  const [state, dispatch] = useStateValue();
  const qpsResponse =
    state.response?.meta?.serviceResponse?.queryParsedResponse;
  const categoryField = state.config?.search?.mapping?.category_field;
  const qpsFilterCategories = qpsResponse?.data.filters
    .find((e) => e.field_name == categoryField)
    ?.values.map((e) => {
      return e + " => " + state.config?.category_mapping?.[e];
    });
  const qpsConfidentCategories = qpsResponse?.data?.confident_categories?.map((e) => {
    return e + " => " + state.config?.category_mapping?.[e];
  });

  const [environments, setEnvironments] = useState([]);
  const [envReady, setEnvReady] = useState(false);
  const history = useHistory();
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("current_env")) || 0
  );

  const inspectResponse = {
    api: state.response,
    request: searchRequest,
    qpsCategories: {
      filterCategories: qpsFilterCategories,
      confidentCategories: qpsConfidentCategories,
    },
    state: { ...state, schema: null },
  };

  // console.log("DEBUG",inspectResponse,qpsResponse)

  const [debugResponse, setDebugResponse] = useState({});

  const setConfigs = (config) => {
    if (typeof config != "object") {
      console.error("CONFIG is not an object. Please set a valid config");
    }
    const modifiedConfig = convertConfig(config);
    dispatch({ type: "SET_STATE", state: { config: modifiedConfig } });
    setSession(config.access_token, config.refresh_token);
  };

  const addEnvironment = (config) => {
    const exists = environments.find((e) => e.name == config.name);
    if (exists) {
      alert("Environment already exists with this name");
      return false;
    }
    const newEnvironments = [...environments, config];
    setCurrent(newEnvironments.length - 1);
    // console.log(newEnvironments.length - 1);
    setEnvironments(newEnvironments);
    window.location.reload();
    return true;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const filereader = new FileReader();
    filereader.onload = (e) => {
      const config = JSON.parse(e.target.result);
      addEnvironment(config);
      setConfigs(config);
      setAddNewDialog(false);
    };
    filereader.readAsBinaryString(file);
  };

  const deleteEnvironment = (i) => {
    const newEnv = [...environments];
    newEnv.splice(i, 1);
    setEnvironments(newEnv);
    setCurrent(0);
  };

  const saveEnvironment = () => {
    const currentEnvIndex = Number(editDialog);
    const newEnv = [...environments];
    newEnv[currentEnvIndex] = JSON.parse(newConfig);
    setEnvironments(newEnv);
    setEditDialog("");
    setNewConfig("");
  };

  const addConfigFromFile = () => {
    const success = addEnvironment(JSON.parse(newConfig));
    if (success) {
      setAddNewDialog(false);
    }
  };

  // const updateDebugResponse = () => {
  //   const debug = inspectResponse;
  //   const output = eval(debugQuery);
  //   if (debugQuery) setDebugResponse(output);
  //   else setDebugResponse(inspectResponse);
  // };

  const updateDebugResponseGlobal = (filterQuery) => {
    // console.log("FILTERQUERY", filterQuery);
    // below line is needed for eval to work
    const debug = inspectResponse;
    if (filterQuery == "search") {
      return setDebugResponse(inspectResponse);
    }
    const output = eval(filterQuery);
    if (filterQuery) setDebugResponse(output);
    else {
      setDebugResponse({});
    }
  };

  const getSearchRequest = async () => {
    const request = await searchAPI.query(
      state.query,
      state.config,
      state,
      true
    );
    setSearchRequest(request);
    return request;
  };

  useEffect(() => {
    updateDebugResponseGlobal(state.currentResponse?.filter);
  }, [state.currentResponse?.filter]);

  useEffect(() => {
    if (current !== undefined) {
      setEnvReady(false);
      if (environments[current]) setConfigs(environments[current]);
      localStorage.setItem("current_env", current);
      setEnvReady(true);
    }
  }, [current]);

  useEffect(() => {
    if (environments.length > 0) {
      localStorage.setItem("environments", JSON.stringify(environments));
    }
  }, [environments]);

  useEffect(() => {
    let environments = localStorage.getItem("environments");
    if (environments) {
      environments = JSON.parse(environments);
      setEnvironments(environments);
      setConfigs(environments[current]);
    }

    setEnvReady(true);
  }, []);

  useEffect(() => {
    if (state.response) {
      getSearchRequest().then((e) => {
        setDebugResponse({ ...inspectResponse, request: e });
      });
    }
  }, [state.response]);

  return (
    <Router>
      <AppBar position="static" style={{ boxShadow: "none" }}>
        <Toolbar style={{ backgroundColor: "#282c34" }}>
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/");
              }}
              display="flex"
              alignItems="flex-end"
            >
              <Typography variant="h5">TOPIQ</Typography>&nbsp;
              <Typography variant="caption">Retail</Typography>
            </Box>
            {/* <SearchBar /> */}
            {environments.length > 0 ? (
              <AutoSuggest />
            ) : (
              <Typography>Please add a project to proceed</Typography>
            )}
            <Box>
              <Button
                variant="outlined"
                style={{
                  textTransform: "initial",
                  color: "white",
                  border: "1px solid white",
                }}
                onClick={() => setConfigOpen(true)}
              >
                {environments.length > 0 ? (
                  <>
                    {environments[current]?.name} <KeyboardArrowDownIcon />{" "}
                  </>
                ) : (
                  "Add"
                )}
              </Button>
              {/* <IconButton style={{ color: "white",marginLeft:10,fontSize:10 }}>
                <img src={autocompleteIcon} height={30} width={30} style={{objectFit:'contain'}} />
              </IconButton> */}
              <IconButton
                disabled={!state.response}
                style={{ color: "white" }}
                onClick={() => {
                  dispatch({
                    type: "SET_STATE",
                    state: {
                      responsePopup: true,
                      currentResponse: {
                        filter: "search",
                      },
                    },
                  });
                  // setResponsePopup(true);
                }}
              >
                <InfoIcon
                  style={{ color: !state.response ? "grey" : "white" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        <Dialog open={configOpen} fullWidth>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">Select a Project</Typography>
              <Button
                color="primary"
                onClick={() => setAddNewDialog(true)}
                variant="contained"
              >
                Add new <AddIcon />
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableCell style={{ padding: 0 }}>Name</TableCell>
                <TableCell style={{ padding: 0 }}>Edit</TableCell>
                <TableCell style={{ padding: 0 }}>Delete</TableCell>
              </TableHead>
              <TableBody>
                {environments.map((env, ind) => {
                  return (
                    <TableRow hover style={{ cursor: "pointer" }}>
                      <TableCell style={{ padding: 0 }}>
                        <Link
                          onClick={() => {
                            setCurrent(ind);
                            setConfigOpen(false);
                          }}
                        >
                          {env.name}
                        </Link>
                      </TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <IconButton
                          onClick={() => setEditDialog(ind.toString())}
                          size="small"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <IconButton
                          onClick={() => deleteEnvironment(ind)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfigOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          transitionDuration={200}
          open={addNewDialog}
          fullWidth
        >
          <DialogTitle>Add a project</DialogTitle>
          <DialogContent>
            <DialogContentText>File</DialogContentText>
            <Input type="file" onChange={handleFileChange} />
            <Box py={2}>Or</Box>
            <Box>
              <textarea
                onChange={(e) => setNewConfig(e.target.value)}
                style={{
                  height: 200,
                  width: "100%",
                  background: "black",
                  color: "white",
                }}
                placeholder="Paste the json"
              ></textarea>
              <Button
                variant="contained"
                onClick={addConfigFromFile}
                color="primary"
              >
                Save
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddNewDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog transitionDuration={200} open={!!editDialog} fullWidth>
          <DialogTitle>
            Editing {environments[Number(editDialog)]?.name}
          </DialogTitle>
          <DialogContent>
            <textarea
              onChange={(e) => setNewConfig(e.target.value)}
              style={{
                minHeight: 300,
                width: "100%",
                background: "#2A2D36",
                color: "white",
                borderRadius: 5,
              }}
            >
              {JSON.stringify(environments[Number(editDialog)], null, 2)}
            </textarea>
            {/* <JSONInput
              id="a_unique_id"
              placeholder={environments[Number(editDialog)]}
              theme={dark_vscode_tribute}
              locale={locale}
              height="300px"
              width="100%"
              onChange={e=>setNewConfig(e.json)}
              waitAfterKeyPress={300}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={saveEnvironment}>Save</Button>
            <Button onClick={() => setEditDialog("")}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={state.responsePopup} fullWidth>
          <DialogTitle>{state.currentResponse?.service} Response</DialogTitle>
          <DialogContent>
            {/* <Box display="flex">
              <TextField
                style={{
                  flex: 1,
                }}
                label={false}
                value={debugQuery}
                onChange={(e) => setDebugQuery(e.target.value)}
              />
              <Button onClick={updateDebugResponse}>GET</Button>
            </Box> */}
            {/* <Tabs>
              <Tab label="A">A</Tab>
              <Tab label="B">B</Tab>
              <Tab label="C">C</Tab>
            </Tabs> */}
            <ReactJson
              collapsed={1}
              displayDataTypes={false}
              name={state.currentResponse?.service || "debug"}
              enableClipboard={(e) =>
                navigator.clipboard.writeText(JSON.stringify(e.src, null, 2))
              }
              style={{ height: 300, overflow: "auto", marginTop: 20 }}
              src={debugResponse}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch({
                  type: "SET_STATE",
                  state: { responsePopup: false },
                });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </Router>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

export default Navbar;
