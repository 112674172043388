import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, ListItemSecondaryAction, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import useStateValue from "../hooks/useStateValue";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
}));

export default function FacetItem({ value, labelId, onToggle }) {
  const classes = useStyles();
  const [{ config }] = useStateValue();
  const [checked, setChecked] = useState(value.selected);
  const handleToggle = () => {
    setChecked((prev) => !prev);
    onToggle(!checked);
  };

  useEffect(() => {
    setChecked(value.selected);
  }, [value]);

  // return (
  //   <Box>
  //     <input
  //       id={config.category_mapping?.[value.label] || value.label}
  //       type="checkbox"
  //       checked={checked}
  //       onChange={handleToggle}
  //     />
  //     <label style={{fontSize:12}} htmlFor={config.category_mapping?.[value.label] || value.label}>
  //       {config.category_mapping?.[value.label] || value.label}
  //     </label>
  //   </Box>
  // );
  return (
    <List className={classes.root}>
      <ListItem key={value} dense button onClick={handleToggle}>
        <ListItemIcon style={{ minWidth: 20 }}>
          <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            style={{ color: "black", padding: 0, transform: "scale(0.8)" }}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>
        <ListItemText
          primary={config.category_mapping?.[value.label] || value.label}
        />
        <ListItemSecondaryAction>
          <Typography
            style={{
              background: "#ECF1F1",
              fontSize: 10,
              padding: 4,
              borderRadius: 3,
            }}
          >
            {value.count}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
