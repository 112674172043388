import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStateValue from "../hooks/useStateValue";

function Authorize() {
  const [, dispatch] = useStateValue();
  const history = useHistory()
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const tokens = Object.fromEntries([...url]);
    dispatch({ type: "SET_STATE", state: { tokens } });
    history.push("/")
  }, []);
  return <div>Authorizing...</div>;
}

export default Authorize;
