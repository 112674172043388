export const searchRequest = {
  query: "",
  implicitFilters: [],
  explicitFilters:[],
  attributeList: {
    fieldList: [],
  },
  pagination: {
    page: 0,
    pageSize: 40,
  },
  grouping: {},
  enableAutoCorrection: false,
  productsBoostEnabled: false,
  enableQueryParser: false,
  enableDynamicFacets: false,
  enableRules: false,
  debugMode: true,
};


const completeRequest = {
  query: "",
  userId: "",
  listId: "",
  facets: {},
  implicitFilters: [],
  explicitFilters: [],
  exclusionFilters: [],
  attributeList: {
    fieldList: [],
  },
  sort: {},
  pagination: {
    page: 0,
    pageSize: 40,
  },
  grouping: {
    groupingField: "baseProductColourGroup_string",
    sortableField: "_score",
    enable: false,
  },
  enableAutoCorrection: false,
  productsBoostEnabled: false,
  enableQueryParser: false,
  enableDynamicFacets: false,
  enableRules: false,
  abFlags: "inviz.search-inviz",

  isBoostCacheEnabled: true,
  isDynamicCacheEnabled: false,
  isQPSCacheEnabled: true,
  isRulesCacheEnabled: true,
  isSpellCacheEnabled: true,
  isAggrCacheEnabled: false,
  debugMode: true,
  dbType: "ELASTIC",
};
