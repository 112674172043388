import { serviceClient } from "./client";

const indexerAPI = {};

const base = "https://api-dev.topiq.ai/indexer-api";

let app_id = null;

indexerAPI.setAppId = (id) => {
  app_id = id;
};

const EVENT = {
  SEARCH: "SearchEvent",
  CART: "CartEvent",
  ORDER: "OrderEvent",
  AUTOSUGGEST: "AutoSuggestEvent",
  FILTER: "FilterEvent",
  SORT: "SortEvent",
  PDP: "PDPEvent",
  WISHLIST: "WishListEvent",
};

const Event = (data, event) => {
  return {
    ...data,
    event_type: event,
    event_time: Date.now(),
    session_id: sessionStorage.getItem("session_id"),
  };
};

indexerAPI.autosuggestEvent = (
  search_term,
  autosuggest_prefix,
  autosuggest_position
) => {
  const event = Event(
    { search_term, autosuggest_prefix, autosuggest_position },
    EVENT.AUTOSUGGEST
  );
  return indexerAPI.submitEvent(event);
};

indexerAPI.searchEvent = (search_term, search_result_count) => {
  const event = Event({ search_term, search_result_count }, EVENT.SEARCH);
  return indexerAPI.submitEvent(event);
};

indexerAPI.filterEvent = (search_term, filters) => {
  const event = Event({ search_term, filters }, EVENT.FILTER);
  return indexerAPI.submitEvent(event);
};

indexerAPI.sortEvent = (search_term, sort) => {
  const event = Event({ search_term, sort }, EVENT.SORT);
  return indexerAPI.submitEvent(event);
};

indexerAPI.pdpEvent = (search_term, product_id, click_position) => {
  const event = Event({ search_term, product_id, click_position }, EVENT.PDP);
  return indexerAPI.submitEvent(event);
};

indexerAPI.cartEvent = (search_term, product_id) => {
  const event = Event({ search_term, product_id }, EVENT.CART);
  return indexerAPI.submitEvent(event);
};

indexerAPI.orderEvent = (search_term, qty, price, product_id) => {
  const sales_info = { qty, price, product_id };
  const event = Event({ search_term, sales_info }, EVENT.ORDER);
  return indexerAPI.submitEvent(event);
};

indexerAPI.submitEvent = (event) => {
  serviceClient.post(`${base}/clickstream/publish`, [event], {
    params: { app_id },
  });
};

export default indexerAPI;
