/* eslint-disable react/prop-types */
import React from "react";
import { useReducer } from "react";

export const StateContext = React.createContext();

function StateProvider({ reducer, initialState, children }) {
  return (
    <StateContext.Provider
      value={useReducer(reducer, initialState, (state) => state, "App")}
    >
      {children}
    </StateContext.Provider>
  );
}

export default StateProvider;
