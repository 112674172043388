import React from 'react'
import { useEffect } from 'react'

function Login() {
    useEffect(() => {
        const redirect = window.location.origin+"/authorize"
        window.location.href = `https://auth.inviz.ai/v1/auth/login?redirect_uri=${redirect}`
    }, [])
    
  return (
    <div>Login</div>
  )
}

export default Login