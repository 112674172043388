import { searchClient } from "./client";
import { searchRequest } from "../resources/search";

const searchAPI = {};

searchAPI.query = async (query, config, state, returnQuery = false) => {
  const urlConfig = config.search.url;
  let url = `${urlConfig.base}${urlConfig.path.name}`;
  for (let param in urlConfig.path.params) {
    url = url.replace(`{${param}}`, urlConfig.path.params[param]);
  }
  const body = { ...searchRequest };
  body.query = query;
  const filters = [];
  for (const f in state.filters) {
    if (state.filters[f]?.length > 0) {
      filters.push({
        field_name: f,
        required: true,
        type: "term",
        values: state.filters[f],
      });
    }
  }
  body.explicitFilters = filters;
  for (const service in state.services) {
    const s = state.services[service];
    body[s.field] = s.enabled;
  }
  body.spellOpt = state.spellOpt;
  if (state.sortBy) {
    body.sort = state.sortBy;
  }
  if (returnQuery) {
    return body;
  }
  return await searchClient.post(url, body);
};

export default searchAPI;
