import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Facets from "../components/Facets";
import ProductCard from "../components/ProductCard";
import { getURL } from "./util";
import useStateValue from "../hooks/useStateValue";
import searchAPI from "../api/search";
import { useHistory, useParams } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Close";
import AntSwitch from "../components/widgets/Switch";
import Filters from "../components/Filters";
import Messaging from "../components/Messaging";
import { throttled } from "../utils/throttling";
import ProductInfoDialog from "../components/ProductInfoDialog";
import adminConsoleAPI from "../api/adminConsole";
import Sorting from "../components/Sorting";
import Navbar from "../components/Navbar";
import indexerAPI from "../api/indexer";
import Services from "../components/Services";
import Product from "../components/Item/Product";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [facets, setFacets] = useState([]);
  const [meta, setMeta] = useState({});
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [state, dispatch] = useStateValue();
  const [noResults, setNoResults] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const services = state.services;
  const [responseStatus, setResponseStatus] = useState();
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();

  const adjustParams = () => {
    const newParams = new URLSearchParams();
    newParams.set("search", state.query);

    for (const svc in state.services) {
      if (state.services[svc].enabled)
        newParams.set(svc, state.services[svc].enabled);
    }

    for (const filter in state.filters) {
      for (const value of state.filters[filter]) {
        newParams.append(filter, value);
      }
    }
    history.push("?" + newParams.toString());
  };

  const removeFilter = (key, value, index) => {
    const newFilters = { ...state.filters };
    newFilters[key].splice(index, 1);
    dispatch({ type: "SET_STATE", state: { filters: newFilters } });
  };

  const fetchResults = () => {
    setCurrentProduct(undefined);
    if (state.query) {
      setNoResults(false);
      adjustParams();
      dispatch({ type: "SET_STATE", state: { loading: true } });
      setProducts([]);
      searchAPI
        .query(state.query, state.config, state)
        .then((resp) => {
          if (resp.data.meta.urlRedirect) {
            window.location.href = resp.data.meta.urlRedirect;
            return;
          }
          if (state.query != state.prevQuery) {
            // console.log("QQ", state.query, state.prevQuery);
            indexerAPI.searchEvent(
              state.query,
              resp.data?.meta?.pagination?.totalProducts || 0
            );
          } else {
            setPrevSearchTerm(state.query);
          }
          setResponseStatus(resp.status);
          setProducts(resp.data.data);
          if (!resp.data?.data || resp.data?.data.length == 0) {
            console.log("NO resuls");
            setNoResults(true);
          }
          setMeta(resp.data.meta || {});
          setFacets(resp.data?.meta?.facets || []);
          dispatch({
            type: "SET_STATE",
            state: {
              loading: false,
              response: { ...resp.data, duration: resp.duration },
              currentResponse: {
                filter: "search",
              },
            },
          });
        })
        .catch((err) => {
          console.log("ERR", err);
          setResponseStatus(err.response.status);
          dispatch({
            type: "SET_STATE",
            state: {
              loading: false,
              response: {
                ...err.response.data,
                duration: err.response.duration,
              },
            },
          });
          setNoResults(true);
        });
    }
  };

  const fetchSchema = () => {
    if (state.config.admin_console) {
      // console.log("CONFIG", state.config.admin_console);
      adminConsoleAPI.getSchema(state.config.admin_console).then((e) => {
        const newSchema = e.data?.data.reduce((acc, e) => {
          acc[e.name] = e;
          return acc;
        }, {});
        dispatch({
          type: "SET_STATE",
          state: { schema: newSchema },
        });
      });
    }
  };

  useEffect(() => {
    // throttled(fetchResults, 0.5);
    fetchResults();
  }, [
    state.query,
    state.filters,
    state.services,
    state.spellOpt,
    state.sortBy,
  ]);

  useEffect(() => {
    fetchSchema();
    if (state.config.search) {
      // console.log(state.config.search)
      indexerAPI.setAppId(state.config.search.app_id);
    }
  }, [state.config]);

  useEffect(() => {
    const search = params.get("search");
    const newConfig = { filters: {}, query: "" };
    if (search) {
      newConfig.query = search;
    }
    params.delete("search");
    const newServices = { ...state.services };
    for (const svc in newServices) {
      const service = newServices[svc];
      const v = params.get(svc);
      params.delete(svc);
      newServices[svc].enabled = v == "true" ? true : false;
    }
    newConfig.services = newServices;
    for (let item of params) {
      newConfig["filters"][item[0]] = params.getAll(item[0]);
    }
    dispatch({ type: "SET_STATE", state: newConfig });
  }, []);

  return (
    <>
      <Box display="flex" flex={1} width="100%" maxHeight="100%">
        {facets.length > 0 && (
          <Box
            width={280}
            display="flex"
            flexDirection="column"
            borderRight="1px solid lightgrey"
          >
            <Box p={2}>
              <Typography variant="h6" style={{ color: "black" }}>
                Filters
              </Typography>
            </Box>
            <Box flex={1} overflow="auto">
              <Facets facets={facets} />
            </Box>
          </Box>
        )}
        <Box flex={1} overflow="auto">
          <Box
            px={2}
            py={1}
            borderBottom="1px solid lightgrey"
            position="sticky"
            bgcolor="white"
            display="flex"
            justifyContent="space-between"
            top={0}
            zIndex={10}
            className="searchMessage"
          >
            <Messaging />
            <Services />
          </Box>

          <Box mt={1} ml={2} mr={3} display="flex" justifyContent="center">
            {!state.loading && (
              <Box flex={1} display="flex" alignItems="center">
                <Filters />
              </Box>
            )}
            <Box>
              <Sorting />
            </Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gridGap={10}
            p={2}
          >
            {products?.map((product, i) => {
              return (
                <ProductCard
                  product={product}
                  onClick={() => {
                    const productId =
                      product[state.config.search.mapping.productId];
                    const position = i + 1;
                    indexerAPI.pdpEvent(state.query, productId, position);
                    setCurrentProduct(i);
                  }}
                />
              );
            })}
            <ProductInfoDialog
              product={products?.[currentProduct]}
              onToggle={() => setCurrentProduct()}
            />
            {state.loading && (
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {noResults && (
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography style={{ color: "black", margin: "50px 0" }}>
                  No Results found...
                </Typography>
                <Messaging altQuery />
                {responseStatus == 401 && (
                  <Box m={2}>
                    <Typography>Token is expired</Typography>
                  </Box>
                )}
                {responseStatus == 500 && (
                  <Box m={2}>
                    <Typography>Something went wrong</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
