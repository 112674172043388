import * as React from "react";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Paper,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import DirectionsIcon from "@material-ui/icons/Directions";
import { useState } from "react";
import { useEffect } from "react";
import useStateValue from "../hooks/useStateValue";

export default function SearchBar(params) {
  const [state, dispatch] = useStateValue();
  const [textValue, setTextValue] = useState(params.query);
  //   useEffect(() => {
  //     setTextValue(state.query);
  //   }, [state.query]);

  return (
    <Paper
      component="form"
      style={{
        display: "flex",
        width: "500px",
        alignItems: "center",
        height: "40px",
      }}
    >
      <TextField
        placeholder="Search Products"
        inputProps={{ "aria-label": "search products" }}
        {...params}
        onChange={(e) => {
          setTextValue(e.target.value);
          dispatch({
            type: "SET_STATE",
            state: { searchPrefix: e.target.value },
          });
          params.onChange(e);
        }}
        value={state.query}
        label={false}
      />
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          params.onKeyPress({
            target: { value: textValue },
            key: "Enter",
            preventDefault: () => {},
          });
        }}
        // type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
