import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "typeface-roboto";
import * as serviceWorker from "./serviceWorker";
import initialState from "./store/initialState"
import { StateInspector } from "reinspect";
import StateProvider from "./store/StateProvider";
import reducer from "./store/reducers";

ReactDOM.render(
  <React.StrictMode>
    <StateInspector name="topiq-demo-ui">
      <StateProvider
        initialState={initialState}
        reducer={reducer}
      >
        <App />
      </StateProvider>
    </StateInspector>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
