import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useStateValue from "../hooks/useStateValue";

function Sorting() {
  const [state, dispatch] = useStateValue();
  const [sortBy, setSortBy] = useState("relevance");

  let sortFields = [{ value: `relevance`, label: `Relevance` }]

  const sortableFields = Object.keys(state?.schema || {})
    .filter((e) => state?.schema[e].sortable)
    .map((e) => [
      { value: `${e},asc`, label: `${e} - low to high` },
      { value: `${e},desc`, label: `${e} - high to low` },
    ])
    .flat(1);

  sortFields = [...sortFields,...sortableFields]

  useEffect(() => {
    const sortByObj = sortBy == "relevance" ? null : { sortBy: [sortBy] };
    dispatch({
      type: "SET_STATE",
      state: { sortBy: sortByObj },
    });
  }, [sortBy]);
  if (state.loading || !state.query) return null;

  return (
    <Box display="flex" flexDirection="column">
      <FormControl
        style={{ alignSelf: "flex-end"}}
        sx={{ minWidth: 120 }}
        size="small"
      >
        <Select
          labelId="demo-select-small"
          value={sortBy}
          renderValue={(r) => "Sort By: " + sortFields?.find(e=>e.value==r)?.label}
          variant="outlined"
          onChange={(e) => setSortBy(e.target.value)}
          style={{ outline: "1px solid black", fontSize: 14}}
        >
          {sortFields.map((e) => (
            <MenuItem style={{ fontSize: 14 }} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Sorting;
