import React from "react";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Authorize from "./pages/Authorize";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (!sessionStorage.getItem("session_id")) {
      const session_id = uuidv4();
      sessionStorage.setItem("session_id", session_id);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <header className="App-header">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/authorize">
              <Authorize />
            </Route>
          </Switch>
        </header>
      </div>
    </Router>
  );
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
