import { Field } from "../pages/util";
import { serviceClient } from "./client";

const autosuggestAPI = {};

autosuggestAPI.suggestions = async (query, config) => {
  const queryParams = config.url.query_params;
  const params = {};
  for (let field in queryParams) {
    const value = Field(config.url.query_params, field, query);
    params[field] = value
  }
  if(typeof queryParams.query !== 'function'){
    params.query = query
  }
  return await serviceClient.get(`${config.url.base}${config.url.path.name}`, {
    params,
  });
};

export default autosuggestAPI;
