import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { useState } from "react";
import SearchBar from "./SearchBar";
import useStateValue from "../hooks/useStateValue";
import autosuggestAPI from "../api/autosuggest";
import { throttled } from "../utils/throttling";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import IndexerAPI from "../api/indexer";

export default function AutoSuggest() {
  const [suggestions, setSuggestions] = useState([]);
  const [state, dispatch] = useStateValue();
  const [autosuggestValue, setAutosuggestValue] = useState({});
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const storeState = (query, { data, meta }) => {
    const suggestionsDebug = [
      ...state.suggestionsDebug,
      { query, data, meta },
    ].slice(-10);
    dispatch({ type: "SET_STATE", state: { suggestionsDebug } });
  };

  const fetchSuggestions = async (query) => {
    const response = await autosuggestAPI.suggestions(
      query,
      state.config.autosuggest
    );
    const dataExtractor =
      state.config.autosuggest?.response?.data ||
      function (d) {
        return d;
      };
    const suggestions = dataExtractor(response.data).map((e, i) => ({
      ...e,
      position: i + 1,
    }));
    storeState(query, response.data);
    setSuggestions(suggestions);
  };

  const onQueryChange = (e) => {
    setOpen(true);
    const q = e.target.value;
    setAutosuggestValue({ suggestedWord: q });
    if (!q) {
      return;
    }
    throttled(() => fetchSuggestions(q), 0.2);
  };

  const setQuery = (value) => {
    // alert("CALLING SET Q "+value)
    dispatch({
      type: "SET_STATE",
      state: {
        query: value,
        prevQuery: state.query,
        loading: true,
        filters: {},
        spellOpt: "DEFAULT",
      },
    });
  };

  const onKeyPressHandler = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      setQuery(e.target.value);
      setOpen(false);
    }
  };

  const renderOption = (row) => {
    const func =
      state.config.autosuggest?.response?.render ||
      function (d) {
        return d.suggestedWord;
      };
    const regex = new RegExp(`(${autosuggestValue.suggestedWord})(.*)`, "i");
    let sugg = func(row);
    const groups = sugg.match(regex);
    if (groups) {
      sugg = sugg.toString().replace(groups[2], `<b>${groups[2]}</b>`);
    }
    return <div dangerouslySetInnerHTML={{ __html: sugg }}></div>;
  };

  const handleChangeQuery = (e, value) => {
    // console.log(state.searchPrefix, value);
    if (value) {
      setAutosuggestValue(value);
    }

    if (!value) {
      return;
    }
    if (state.config?.autosuggestFilters) {
      dispatch({ type: "SET_STATE", state: { filters: value.filters } });
    }
    IndexerAPI.autosuggestEvent(
      value.suggestedWord,
      state.searchPrefix,
      value.position
    );
    setQuery(value.suggestedWord);
    setOpen(false);
  };

  useEffect(() => {
    setAutosuggestValue({ suggestedWord: state.query });
  }, [state.query]);

  return (
    <Autocomplete
      id="autocomplete"
      options={suggestions}
      onChange={handleChangeQuery}
      // open={open}
      value={autosuggestValue}
      getOptionLabel={(option) => option.suggestedWord?.toString()}
      renderOption={renderOption}
      filterOptions={(options) => options}
      key={state.query}
      renderInput={(params) => (
        <SearchBar
          onChange={onQueryChange}
          onKeyPress={onKeyPressHandler}
          {...params}
          value={state.query}
          variant="outlined"
        />
        // <input type="text" {...params} onChange={console.log} defaultValue={state.query} />
      )}
    />
  );
}
