import { searchClient, serviceClient } from "../api/client";

export const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    }
    serviceClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    searchClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete serviceClient.defaults.headers.common.Authorization;
    delete searchClient.defaults.headers.common["x-authorization"];
  }
};
