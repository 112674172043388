const reducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "SET_CONFIG":
      newState = {
        ...state,
        config: action.config,
      };
      break;
    case "SET_STATE":
      newState = {
        ...state,
        ...action.state,
      };
      break;
    default:
      console.log("CALLING DEFAULT");
      newState = state;
  }
  window.STATE = newState;
  return newState;
};

export default reducer;
