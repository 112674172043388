import { Box, Typography } from "@material-ui/core";
import React from "react";
import useStateValue from "../../hooks/useStateValue";
import { Field } from "../../pages/util";

function Product({ item }) {
  const [state] = useStateValue();
  const config = state?.config.search;
  return (
    <Box>
      <Box>
        <img src={Field(config.mapping?.image,item)} height={50} width={50} />
      </Box>
      <Box flex={1}>
        <Typography variant="h6">
          {Field(config.mapping?.brandName, item)}
        </Typography>
      </Box>
    </Box>
  );
}

export default Product;
