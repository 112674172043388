import * as React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  styled,
  Typography,
  CardActions,
  Box,
  Button,
} from "@material-ui/core";
import useStateValue from "../hooks/useStateValue";
import { Field } from "../pages/util";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductCard({ product, onClick, ...props }) {
  const [expanded, setExpanded] = React.useState(false);
  const [state] = useStateValue();
  const config = state.config;
  const productInfo = { ...product };
  for (let field in config.search.mapping) {
    productInfo[field] = productInfo[config.search.mapping[field]];
  }
  let productTitle = productInfo.productName;

  for (let word of (
    state.response?.meta?.serviceResponse?.spellCorrectedResponse
      ?.corrected_query || state.query
  ).split(" ")) {
    const regex = new RegExp(`\\b${word}\\b`, "ig");
    try {
      productTitle =
        productTitle?.replace(
          regex,
          (e) => `<b style="background:lightyellow">${e}</b>`
        ) || productTitle;
    } catch (err) {
      console.log("ERR prod title", productTitle, productInfo);
    }
  }
  const currency = config.currency;
  const currencyFormatter = new Intl.NumberFormat(currency.name, {
    ...currency.options,
  });

  return (
    <Card
      style={{
        minHeight: 260,
        maxWidth: 400,
        borderRadius: 0,
        border: "none",
        // boxShadow: "none",
        // outline: "1px solid lightgrey",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        // padding: 10,
      }}
      className="productHover"
      onClick={onClick}
      {...props}
    >
      {/* <Box display="flex" flexDirection="column" height="100%">
                <Box flex={1} height="100%" bgcolor="red">
                    <img style={{ maxHeight:'100%' }} src='https://media.istockphoto.com/photos/mens-shirt-picture-id488160041?k=20&m=488160041&s=612x612&w=0&h=OH_-skyES8-aeTvDQHdVDZ6GKLsqp6adFJC8u6O6_UY=' />
                </Box>
                <Box bgcolor="yellow">Hello</Box>
            </Box> */}
      <CardMedia
        component="img"
        height="140"
        width="140"
        image={
          Field(config.search?.mapping, "image", product) ||
          "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
        }
        alt={productInfo.brandName}
        style={{
          objectFit: "contain",
          userSelect: "none",
          pointerEvents: "none",
        }}
      />
      <Box
        p={1}
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box pb={1}>
          <Typography style={{ color: "#0C2B7B", fontWeight: "bold" }}>
            {Field(config.search?.mapping, "brandName", product)}
          </Typography>
          <Typography
            title={productInfo.productName}
            className="productTitle"
            variant="body2"
            component="p"
            dangerouslySetInnerHTML={{
              __html: Field(config.search?.mapping, "productName", product),
            }}
          ></Typography>
        </Box>
        <Box display="flex">
          <Typography
            style={{
              color: "grey",
            }}
            variant="body2"
            component="div"
          >
            {/* {currencyFormatter.format(
              productInfo.price?.constructor.name == "Array"
                ? productInfo.price[0]
                : productInfo.price
            )} */}
            {Field(config.search?.mapping, "price", product)}
            {/* {product.price} */}
          </Typography>
        </Box>
        {/* <Box display="flex">
          <Button style={{ flex: 1,background:'#282c34' }} variant="contained" color="primary">
            Cart
          </Button>
          <Button style={{ flex: 1,marginLeft:10 }} variant="outlined" color="primary">
            Wishlist
          </Button>
        </Box> */}
      </Box>
    </Card>
  );
}
