import { Box, Typography } from "@material-ui/core";
import React from "react";
import useStateValue from "../hooks/useStateValue";

function Messaging({ altQuery = false }) {
  const [state, dispatch] = useStateValue();
  const meta = state.response?.meta || {};
  const showResponseTime = localStorage.getItem("responseTime") == 'true';
  const spellResponse = meta.serviceResponse?.spellCorrectedResponse || {};
  const changeQuery = (value) => {
    const q = value == "OUT" ? spellResponse?.corrected_query : state.query;
    dispatch({ type: "SET_STATE", state: { spellOpt: value, query: q } });
  };
  return (
    <Box>
      {meta.pagination?.pageSize && (
        <>
          <Typography style={{ color: "black" }}>
            Results {meta.pagination?.page * meta.pagination?.pageSize + 1}
            {" - "}
            {Math.min(
              (meta.pagination?.page + 1) * meta.pagination?.pageSize,
              meta.pagination?.totalProducts
            )}
            {" of "}
            {meta.pagination?.totalProducts}
            {` for `}
            <b>
              "
              {meta?.spellOpt == "DEFAULT" ? (
                <>
                  {state.services.spellCheck.enabled
                    ? spellResponse?.corrected_query
                    : state.query}
                </>
              ) : (
                <>{state.query}</>
              )}
              "
            </b>
            {"  "}
            {showResponseTime && (
              <span
                style={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  fontSize: 14,
                  background: "black",
                  borderRadius: 3,
                  color: "white",
                  marginRight: 5,
                }}
              >
                {state.response?.duration} ms
              </span>
            )}
          </Typography>
          {state.services.spellCheck.enabled &&
            state.query != spellResponse?.corrected_query &&
            meta.spellOpt == "DEFAULT" && (
              <Typography>
                Search instead for{" "}
                <i className="searchInstead" onClick={() => changeQuery("IN")}>
                  {state.query}
                </i>
              </Typography>
            )}
        </>
      )}
      {state.services.spellCheck.enabled &&
        spellResponse?.corrected_query &&
        state.query != spellResponse?.corrected_query &&
        (meta.spellOpt == "IN" || altQuery) && (
          <Typography>
            Did you mean{" "}
            <i className="searchInstead" onClick={() => changeQuery("OUT")}>
              {spellResponse?.corrected_query}
            </i>{" "}
            ?
          </Typography>
        )}
    </Box>
  );
}

export default Messaging;
