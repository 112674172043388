import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import useStateValue from "../hooks/useStateValue";
import AntSwitch from "./widgets/Switch";

const Services = () => {
   const [state,dispatch] =  useStateValue();
   const handleServiceToggle = (svc, checked) => {
    const newServices = { ...state.services };
    newServices[svc].enabled = checked;
    dispatch({ type: "SET_STATE", state: { services: newServices } });
  };
  return (
    <Box display="flex">
      {Object.keys(state.config).length > 0 &&
        Object.keys(state.services).map((svc) => {
          const service = state.services[svc];
          return (
            <Box display="flex" alignItems="center" mx={2}>
              <AntSwitch
                checked={service.enabled}
                onChange={(e, checked) => handleServiceToggle(svc, checked)}
                name="checkedC"
              />
              <Typography
                variant="body2"
                component={Link}
                onClick={() => {
                  dispatch({
                    type: "SET_STATE",
                    state: {
                      responsePopup: true,
                      currentResponse: {
                        filter: service.responseFilter,
                        service: service.name,
                      },
                    },
                  });
                }}
                style={{
                  color: "black",
                  marginLeft: 5,
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
              >
                {service.name}
              </Typography>
            </Box>
          );
        })}
    </Box>
  );
};

export default Services;
