import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactJson from "react-json-view";
import CloseIcon from "@material-ui/icons/Close";
import useStateValue from "../hooks/useStateValue";

function ProductInfoDialog({ product, onToggle }) {
  const [open, setOpen] = useState(!!product);
  const [state] = useStateValue();
  const toggleClose = ()=>{
    setOpen(!open)
    onToggle()
  }
  useEffect(() => {
    if (product) setOpen(true);
  }, [product]);

  return (
    <Dialog fullScreen open={open}>
      <Box display="flex" justifyContent="space-between">
        <DialogTitle>Product Info</DialogTitle>
        <DialogTitle>
          <Button style={{color:'red'}} onClick={toggleClose}>
            Close &nbsp;<CloseIcon style={{ fontSize: 16 }} />
          </Button>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box display="flex">
          <Box flex={1}>
            <img
              style={{ width: "100%" }}
              src={product?.[state.config?.search?.mapping.image]}
            />
          </Box>
          <ReactJson
            displayDataTypes={false}
            theme="shapeshifter:inverted"
            name="info"
            enableClipboard={(e) =>
              navigator.clipboard.writeText(JSON.stringify(e.src, null, 2))
            }
            style={{ height: "100%", overflow: "auto", flex: 2 }}
            src={product}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ProductInfoDialog;
