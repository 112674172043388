import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FacetItem from "./FacetItem";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import useStateValue from "../hooks/useStateValue";
import indexerAPI from "../api/indexer";
import { useHistory } from "react-router-dom";

export default function Facets({ facets }) {
  const [state, dispatch] = useStateValue();
  const [showMore, setShowMore] = useState(facets.map((e) => false));
  const toggleShowMore = (i) => {
    const newShowMore = [...showMore];
    newShowMore[i] = !newShowMore[i];
    setShowMore(newShowMore);
  };
  const [expanded, setExpanded] = useState(
    facets.map((e) => (e.facetType == "GLOBAL" ? true : false))
  );
  const history = useHistory();

  const handleExpanded = (index, v) => {
    const newExpanded = [...expanded];
    newExpanded[index] = v;
    setExpanded(newExpanded);
  };

  const onChangeFilters = (key, value) => {
    const newFilters = { ...state.filters };
    if (newFilters[key]) {
      if (newFilters[key].includes(value)) {
        const index = newFilters[key].findIndex((e) => e == value);
        newFilters[key].splice(index, 1);
      } else {
        newFilters[key].push(value);
      }
    } else {
      newFilters[key] = [value];
    }
    dispatch({ type: "SET_STATE", state: { filters: newFilters } });
  };

  return (
    <div>
      {facets.map((facet, index) => {
        return (
          <Accordion
            expanded={expanded[index]}
            onChange={(e, v) => handleExpanded(index, v)}
            className="custom-accordion"
            style={{ borderRadius: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  color: facet.values.some((e) => e.selected)
                    ? "#0C2B7B"
                    : "black",
                }}
              >
                {facet.displayName}{" "}
                <Typography
                  variant="caption"
                  style={{
                    background: "#ECF1F1",
                    fontSize: 10,
                    padding: 4,
                    borderRadius: 3,
                  }}
                >
                  {facet.values.reduce((acc, e) => acc + Number(e.count), 0)}
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                flexDirection: "column",
                padding: "0px 0px 0px 5px",
                maxHeight: showMore[index] ? "250px" : "180px",
                overflow: showMore[index] ? "auto" : "hidden",
              }}
            >
              {facets.length > 0 &&
                facet.values.map((value, i) => {
                  const labelId = `checkbox-list-label-${value}-${i}`;
                  return (
                    <FacetItem
                      key={labelId}
                      onToggle={(checked) => {
                        // console.log("SETTING PREV");
                        dispatch({
                          type: "SET_STATE",
                          state: { prevQuery: state.query },
                        });
                        if (checked) {
                          const filters = [
                            { name: facet.label, values: [value.label] },
                          ];

                          indexerAPI.filterEvent(state.query, filters);
                        }
                        onChangeFilters(facet.label, value.label);
                      }}
                      value={value}
                      labelId={labelId}
                    />
                  );
                })}
            </AccordionDetails>
            {facet.values?.length > 5 && (
              <Box
                p={2}
                style={{
                  fontSize: 14,
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() => toggleShowMore(index)}
              >
                {showMore[index] ? "show less" : "show more"}
              </Box>
            )}
          </Accordion>
        );
      })}
    </div>
  );
}
